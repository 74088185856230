/* src/components/Navbar.css */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.logo-container img {
    margin-right: 20px;
    height: 40px;
}

.auth-buttons a {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
}

.navbar-user-info {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.navbar-user-info button{
  margin-bottom: 0;
  margin-left: 1rem;
}

.auth-buttons .login {
    background-color: #0147AB;
    color: #fff;
}

.auth-buttons .create-account {
    background-color: #F5F5F5;
    color: #0147AB;
}

.auth-buttons .create-account:hover {
  background-color: #F0F0F0;
}
  