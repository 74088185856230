/* src/components/MainStyles.css */
.courses {
    background-color: #fdfdfd; /* Light gray background */
    padding: 2rem;
    min-height: 100vh; /* Ensure it takes the full viewport height */
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif; /* Apply Roboto font */
  }

  
  .courses h1, .courses p {
    margin: 0;
    margin-top: 10px;
    padding: 1rem 0;
  }
  
  .faculty-categories {
    display: flex;
    background-color: white;
    border-radius: 15px;
    padding: 1rem;
    margin-top: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .faculty-categories h2 {
    margin-top: 0;
    padding: 20px;
  }
  
  .course-options {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin: 3rem
  }
  
  .course-options button {
    margin-bottom: 10px;
  }
  
  .course-image img {
    /* max-width: 400px; */
    margin: 3rem;
    height: 400px;
  }
  
  .video-container {
    margin-top: 10px;
  }

  .form-add-button{
    position: absolute;
    top: 0;
    right: 0;
  }

  .category-link {
    display: block;
    margin-top: 1rem;
    color: #0147AB;
    text-decoration: none;
    font-weight: bold;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  
  .category-link:hover {
    text-decoration: underline;
  }
  
  .course-button {
    background-color: #0147AB;
    color: white;
    border: none;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 1rem 1rem 1rem 0;
    font-weight: bold;
    font-size: 1rem;
  }

  .course-button.assessment-button {
    font-size: 0.8rem;
    margin: 0 0 2rem 0;
  }
  
  .course-button:hover {
    background-color: #0056b3;
  }

  .course-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
.course-details{
    background-color: white;
    border-radius: 15px;
    padding: 1rem;
    margin: 2rem 0 4rem 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    padding-left: 40px;
    position: relative;
    text-align: justify; 
    text-justify: inter-word
}

.course-details.course{
  position: relative;
  padding-bottom: 5rem;
}

.course-button.session-button{
  position:absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
}

.card-button.delete{
  position: absolute;
  top:0;
  right: 0;
  margin: 2rem;
  background-color: red;
}

.card-button.delete:hover:enabled{
  background-color: rgb(244, 5, 5);
}

.course-button.delete{
  float: right;
  background-color: red;
}

.course-button.delete:hover:enabled{
  background-color: rgb(244, 5, 5);
}

.top-link{
  text-decoration: none;
  color: #0147AB;
}

.top-link:hover{
  color: #0147AB;
}

.top-link.active{
  font-weight: bold;
}

/* src/components/Session.css */
.session {
  border: 1px solid #ccc;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.reading-materials {
  margin-bottom: 1rem;
}

.assessment {
  margin-top: 1rem;
  padding: 10px;
}

.assessment hr{
  margin: 2rem 0;
}

/* .question {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.radio-class{
  display: flex;
  align-items: center;
  float: left;

}

.radio-class input{
  float: left;
} */

.score.pass h2{
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.score.pass {
  color: green;
  text-align: center;
}

.score.fail{
  margin: 2rem 0 0 0;
  color: red;
  text-align: center;
}

.score.fail .course-button{
  margin: 0.5rem 0.5rem;
}

.session-div{
  display: flex;
  align-items: center;
}
/* src/components/Course.css */
.course {
  padding: 2rem;
}

.course h1{
  margin: 40px 0 20px 0
}

.course h2{
  margin: 10px 0 30px 0
}

.session-structure{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.session-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  position: relative;
}

.session-header:hover {
  background-color: #f9f9f9;
}

.session-badge{
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 0.8em;
  margin: 0.5rem;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background-color: #28a745; /* Green color */
  border-radius: 0.25rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.expand-collapse-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.session-content {
  padding: 0 10px;
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  /* margin: 10px 0; */
}


.locked-session {
  border: 1px solid #fdfdfd;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: #f9f9f9;
  color: #aaa;
}

.assessment-subtitle{
  margin-bottom: 3rem

}


/* Styles for the toggle buttons */
.report-toggle-button {
  background-color: #0147AB;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.report-toggle-button:hover {
  background-color: #0056b3;
}

/* Styles for the tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  color: #333;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.user-badge {
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  color: white;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}

.user-badge-in-progress {
  background-color: orange;
}

.user-badge-completed {
  background-color: green;
}

.user-badge-locked {
  background-color: red;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.text-content {
  max-width: 50%;
}

.text-content h1 {
  color: #0147AB;
  font-size: 4em;
}

.text-content p {
  color: #333;
  font-size: 1.8em;
  font-weight: bold;
}
.homepage-buttons{
  display: flex;
}
.homepage-buttons button{
  margin-top: 20px;
  background-color: #0147AB;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 1em;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.homepage-buttons .intro-video {
  background-color: #0147AB;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 1em;
  font-weight: bold;
}

.homepage-buttons .course-intro {
  background-color: #f0f0f0;
  color: #0147AB;
}

.homepage-buttons .sessions-assignments {
  background-color: #f0f0f0;
  color: #0147AB;
}

.image-content img {
  max-width: 400px;
  border-radius: 10px;
}