/* src/components/Container.css */
.container {
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Add padding for small screens */
    max-width: 1250px; /* Set a max-width for larger screens */
  }
  
  @media (min-width: 576px) {
    .container {
      padding: 0 30px; /* Increase padding for larger screens */
    }
  }
  
  @media (min-width: 768px) {
    .container {
      padding: 0 50px; /* Further increase padding for medium screens */
    }
  }
  
  @media (min-width: 992px) {
    .container {
      padding: 0 60px; /* Further increase padding for large screens */
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      padding: 0 70px; /* Further increase padding for extra large screens */
    }
  }
  