/* src/components/Card.css */
.card {
    position: relative;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 1.5rem;
    text-align: center;
    margin: 1rem;
    width: 30%;
  }

  .card-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #0147AB;
    color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    font-size: 0.8rem;
  }
  
  .card-image {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    margin-top: 1rem;
  }
  
  .card-title {
    display: block;
    margin: 1rem 0;
    font-size: 1.2rem;
    color: #0147AB;
    text-decoration: none;
    font-weight: bold;
  }
  
  .card-title:hover {
    text-decoration: underline;
  }
  
  .card-button {
    background-color: #0147AB;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .card-button:hover {
    background-color: #0056b3;
  }

  .progress-bar {
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 1rem;
  }
  
  .progress {
    height: 10px;
    background-color: #0147AB;
  }

  .progress-completion{
    margin-top: 3%;
    font-weight: bold;
    text-align: left;
  }
  

/* Session Card */
.session-cards{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem;
}

.sessioncard-image{
    width: 40%;
    
}

.sessioncard-title {
    display: block;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    color: #0147AB;
    text-decoration: none;
    font-weight: bold;
  }

  .mark-as-read-button {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    color: #fff;
    background-color: #6c757d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .mark-as-read-button.read {
    background-color: #28a745;
  }
  
  .mark-as-read-button:hover {
    background-color: #5a6268;
  }

  .card-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .card-button:hover:enabled {
    background-color: #0056b3;
  }