/* src/components/Login.css */
  .login-container {
    width: 100%;
    max-width: 400px;
    margin: 8rem auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-form h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  .login-form .error{
    color: red;
  }

  .login-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .btn {
    background-color: #0147AB;
    color: white;
    border: none;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 1rem 0;
    font-weight: bold;
    font-size: 1rem;
    margin: 0 auto;
    display: block;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }

  .login-text{
    margin-top: 25px;
    text-align: center;
  }

  .login-text a{
    text-decoration: none;
    color: #0056b3;
    font-weight: bold;
  }

  .register{
    margin-top: 2rem;
  }

  select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  select:hover,
  select:focus {
    border-color: #007bff;
  }