/* src/styles.css */

body{
  background-color: #fdfdfd; /* Light gray background */
  font-family: 'Roboto', sans-serif;
}

.homepage, .loginpage {
    padding: 2rem;
  }
  
  form div {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  button {
    padding: 0.5rem 1rem;
    background-color: #282c34;
    color: white;
    border: none;
    cursor: pointer;
  }
  